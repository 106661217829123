import { login } from "../redux/slices/Member/member";
import * as auth0Client from "../utils/auth0Client";
import { useAppDispatch } from "../hooks";
import { navigate } from "gatsby";
const Callback = () => {
  const dispatch = useAppDispatch();

  const onLogin = (user) => {
    dispatch(login(user));
    navigate("/account");
  };
  auth0Client.handleAuthentication(onLogin);
  
  return "Processing authentication...";
};

export default Callback;
